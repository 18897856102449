import React from "react"

import { Section, Columns } from "../../components/Containers"
import { Text, Image } from "../../components/Core"
import { ButtonGroupMap } from "../../components/Button"

function FirstTime(props) {
  return (
    <div className="first-time">
      <div className="column first-time__image mobile">
        <Image publicId={"WOD/DEV/wise-decision-first-patient-visit"} />
      </div>
      <Section
        zeroBottom
        colorBack={props.colorBack}
        className={`${props.colorBack ? "color-back--gray" : ""}`}>
        <Columns
          sideColumnsSize={2}
          className="rounded-top first-time__card"
          data-aos="fade-up"
          data-aos-duration="1200">
          <div className="column mt-2--desktop">
            <Text
              as="h3"
              className="mt-0 has-text-centered-touch"
              style={{ marginBottom: "16px" }}
              text={props.post.firstTimePatient.heading}
            />
            <Text
              as="p"
              className="bold--500 has-text-centered-touch"
              text={props.post.firstTimePatient.text}
            />
            <ButtonGroupMap
              buttons={props.post.firstTimePatient.buttons}
              // isCentered
              noReverse={props.noReverse}
            />
          </div>
          <div className="column is-1"></div>
          <div className="column first-time__image desktop">
            <Image publicId={"WOD/DEV/wise-decision-first-patient-visit"} />
          </div>
        </Columns>
      </Section>
    </div>
  )
}

export default FirstTime
