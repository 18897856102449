import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import { sortReviewsByDate } from "../utils/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FixedFacewallHomepage from "./index/FixedFacewallHomepage"
import StaticHeading from "./index/StaticHeading"
import Sizzle from "./index/Sizzle"
import ImageText from "./index/ImageText"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import FirstTime from "./index/FirstTime"
import InTheCommunity from "./index/InTheCommunity"
import LearnMoreCards from "./index/LearnMoreCards"
// import ImageTexts from "../components/ImageTexts"
import StaticHero from "./index/StaticHero"
import { TestimonialPreview } from "../components/Cards"
import { Image, Text } from "../components/Core"
import { Columns, Section } from "../components/Containers"
import TheaterVideo from "../components/Theater/TheaterVideo"
import TestimonialGrid from "../components/Cards/TestimonialGrid"
import { ProgramToast } from "../components/program-toast"
import { Button } from "../components/Button"
import SmallCTA from "../components/CTA/SmallCTA"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false
    }
  }

  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    const { metaTitle, metaDescription, language } = this.props.pageContext

    const staticStyle = {
      position: "relative",
      overflow: "unset",
      width: "100%",
      zIndex: 1
    }
    const sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200
    }

    const hasReviews = this.props.data.hasReviews.nodes[0].reviews
    let allReviews = this.props.data.allReviews.nodes

    const sortedReviews = sortReviewsByDate(allReviews)

    // shuffleArray(allReviews);

    let fixedFacewallReviews = []
    if (sortedReviews.length >= 8) {
      fixedFacewallReviews = sortedReviews.slice(0, 8)
    } else {
      fixedFacewallReviews = sortedReviews.slice(0, 4)
    }

    let schemaData = null
    if (language === "en") {
      schemaData = {
        presets: post.schemas.presets,
        customSchema: post.schemas.custom,
        dateModified: this.props.pageContext.dateModified,
        language,
        metaTitle: metaTitle,
        description: metaDescription,
        path: this.props.location.pathname
      }
    }

    return (
      <Layout
        className={`main-homepage ${language}`}
        language={language}
        layoutClass="homepage-fab"
        noFab
        pageTitle="main-homepage">
        <SEO
          title={metaTitle}
          description={metaDescription}
          schemaData={schemaData}
          pathname={this.props.location.pathname}
          lang={language}
        />

        <div className="static-container color-back" style={staticStyle}>
          {post.hasSizzle ? (
            <Sizzle
              playing={this.state.playing}
              sizzleButtonStyle={sizzleButtonStyle}
              youtube={post.sizzleYoutube}
              post={post}
              language={language}>
              <StaticHeading
                playing={this.state.playing}
                youtube={post.sizzleYoutube}
                heading={post.hero.heading}
                subheading={post.hero.subheading}
                language={language}
              />
            </Sizzle>
          ) : (
            <StaticHero
              post={post}
              heading={post.hero.heading}
              subheading={post.hero.subheading}
              bgImage={post.hero.bgImage}
              youtube={post.sizzleYoutube}
              language={language}
            />
          )}

          {language == "en" && (
            <>
              {hasReviews && (
                <TestimonialGrid language={language} reviews={allReviews} />
              )}

              {hasReviews && post.featuredReviews.length > 1 && (
                <div className="columns">
                  {post.featuredReviews.map(card => (
                    <div key={card.youtube} className="column">
                      <TestimonialPreview
                        language={language}
                        youtube={card.youtube}
                        image={card.image}
                        heading={card.heading}
                        subheading={card.subHeading}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          <SmallCTA />

          <Section>
            <Columns sideColumnsSize={2}>
              <div className="column">
                <div className="home__about">
                  <div className="home__about--heading">
                    <div
                      className="home__about--image"
                      data-aos="fade-right"
                      data-aos-duration="1200">
                      <Image publicId="WOD/DEV/isolated__wisdom-teeth" />
                    </div>

                    <Text
                      className="color-blue-dark px-40--mobile"
                      as="h2"
                      text={post.homeSectionOne.heading}
                    />
                  </div>
                  <Text
                    useStringReplace
                    className=" px-40--mobile"
                    text={post.homeSectionOne.text}
                  />
                </div>
              </div>
            </Columns>
          </Section>

          <LearnMoreCards language={language} post={post.learnMoreCards} />

          <Section zeroBottom>
            <Columns sideColumnsSize={2}>
              <div className="column px-40--mobile">
                <Text
                  text={`### Specialized Pediatric Care\n\nWise Decision is designed for kids and teens to have their wisdom teeth removed. But their needs differ from adults and need special care and attention. That’s why we proudly have onsite pediatric anesthesiologists on staff who undergo specialized training to administer anesthesia to even our youngest patients.`}
                />
              </div>
            </Columns>
          </Section>
          <Section smallSpacing>
            <Columns sideColumnsSize={2}>
              <div className="column px-40--mobile">
                <Text
                  text={`### A Category 1 Anesthesiology Facility\n\nAnesthesia requires specialized training and the utmost attention to detail. When we built Wise Decision, we wanted to make sure every patient felt safe coming to us for their oral surgery procedures. We intentionally built our practice to align with Category 1 anesthesia standards. These standards align with those of ambulatory surgery centers to prioritize patient safety at all times.`}
                />
                <Button
                  destination="internal"
                  buttonText="Learn more"
                  href="/your-first-visit/"
                  contained
                />
              </div>
            </Columns>
          </Section>

          <FirstTime colorBack language={language} post={post} noReverse />

          {/* {post.homeRatingsReviews.hasThisSection && (
            <Section>
              <AllRatingsReviews
                animation
                heading={post.homeRatingsReviews.heading}
                blurb={post.homeRatingsReviews.blurb}
                language={language}
                buttonHref={post.homeRatingsReviews.buttonOne.href}
                buttonText={post.homeRatingsReviews.buttonOne.buttonText}
              />
            </Section>
          )} */}
        </div>
        {post.toast.hasThisSection && (
          <ProgramToast closeDate={post.toast.closeDate}>
            {post.toast.text}
          </ProgramToast>
        )}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query reviewsIndexQuery($title: String!, $language: String!) {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, language: { eq: $language } }
      limit: 30
    ) {
      nodes {
        ...FacewallData
      }
    }
    hasReviews: allDataJson(filter: { optionsItem: { eq: "build-pages" } }) {
      nodes {
        reviews
      }
    }
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        schemas {
          presets
          custom
        }
        hasSizzle
        hero {
          heading
          subheading
          bgImage
        }
        featuredReviews {
          heading
          subHeading
          youtube
          image
        }
        homeSectionOne {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          # blurb
          buttonOne {
            buttonText
            href
          }
        }
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        toast {
          hasThisSection
          text
          closeDate
        }
      }
    }
  }
`

export default IndexPage
