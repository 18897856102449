import React from "react"

import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import { Text, Image } from "../../components/Core"
import { Button, ButtonGroup } from "../../components/Button"

function InTheCommunity({ heading, blurb, image, buttons }) {
  return (
    <>
      <HorizontalTwoColumns
        className="in-the-community color-back"
        leftColWidth={3}
        rightColWidth={3}
        middleColWidth={0}
        leftColumn={
          <div className="column is-5">
            <Image publicId={image} responsive />
          </div>
        }
        rightColumn={
          <div className="column community-text-block">
            {heading && <h3>{heading}</h3>}
            <Text text={blurb} className="community-markdown" />
            <ButtonGroup noReverse>
              {buttons.map(btn => (
                <Button
                  key={btn.button.href}
                  minimal
                  buttonText={btn.button.buttonText}
                  href={btn.button.href}
                />
              ))}
            </ButtonGroup>
          </div>
        }
      />
    </>
  )
}

export default InTheCommunity
